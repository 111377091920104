[data-amplify-authenticator] {
  --amplify-colors-background-primary: var(--amplify-colors-neutral-90);
  --amplify-colors-background-secondary: var(--amplify-colors-neutral-100);
  --amplify-colors-brand-primary-10: var(--amplify-colors-teal-100);
  --amplify-colors-brand-primary-80: var(--amplify-colors-teal-40);
  --amplify-colors-brand-primary-90: var(--amplify-colors-teal-20);
  --amplify-colors-brand-primary-100: var(--amplify-colors-teal-10);
  --amplify-colors-font-interactive: var(--amplify-colors-white);
  --amplify-components-tabs-item-active-color: var(
    --amplify-colors-neutral-100
  );
  --amplify-components-tabs-item-focus-color: var(--amplify-colors-neutral-90);
  --amplify-components-tabs-item-hover-color: var(--amplify-colors-orange);
  --amplify-components-authenticator-router-background-color: #e0e0e0;
  --amplify-components-authenticator-state-inactive-background-color: #c2c2c2;
}
